import { mediacoreApi } from '../mediacoreApi'

const is_compiler = process.env.GATSBY_IS_SITE_COMPILER === 'true'
const TENANTID = process.env.GATSBY_TENANTID

const getBody = (data: any, type: string) => {
  let data_form = {}
  console.log(data)
  switch (type) {
    case 'contact': {
      data_form = {
        contact: {
          status: true,
          name: data.name,
          phone: data.cellphone,
          email: data.email,
          message: data.text,
        },
      }
      break
    }
    case 'property': {
      data_form = {
        property_query: {
          status: true,
          name: data.name,
          phone: data.cellphone,
          email: data.email,
          message: data.text,
          address: data.property.address,
          property_id: data.property.id,
          url: data.url,
          operation_type: data.property?.operations?.map(
            (item: { operation_type: any }) => item.operation_type,
          ),
          property_type: data?.property?.property_type ?? [data?.property?.type?.name],
        },
      }
      break
    }
    case 'development': {
      data_form = {
        development_query: {
          status: true,
          name: data.name,
          phone: data.cellphone,
          email: data.email,
          message: data.text,
          address: data.development.address,
          url: data.url,
          development: data.development.name,
          property_id: data.development.id,
        },
      }
      break
    }
    case 'services': {
      data_form = {
        appraisal: {
          status: true,
          name: data.name + (data.lastname ? ' ' + data.lastname : ''),
          phone: data.phone,
          email: data.email,
          location: data.location,
          operation_type: [data.operation],
          property_type: [data.type],
          rooms_amount: data.rooms,
          roofed_surface: data.sup_cub,
          total_surface: data.sup_tot,
          age: [data.age],
          garage: data.garage,
          extra_amenities: [data.amenities.join(', ')],
        },
      }
      break
    }
    case 'newsletter': {
      data_form = {
        subscription: { status: true, email: data.email },
      }
      break
    }
    case 'jobs': {
      data_form = {
        application: {
          status: true,
          email: data.email,
          gender: data.gender,
          date: data.date,
          location: data.location,
          phone: data.phone,
          cv: data.file,
        },
      }
      break
    }
  }
  return data_form
}

const contactExtended = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    createForm: builder.mutation({
      query: ({ data, type, site = '' }) => ({
        url: `${process.env.GATSBY_APISERVER}site/email/contact/?client=${
          process.env.GATSBY_CLIENTID
        }&site=${is_compiler ? site : TENANTID}`,
        method: 'POST',
        body: getBody(data, type),
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useCreateFormMutation } = contactExtended
